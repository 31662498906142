import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BrandCampaignService } from "../../brand/brand-campaign/shared/services/brand-campaign.service";
import { PartnersInviteTrackingEvents } from "../../brand/brand-campaign/shared/tracking/partners-invite.tracking-events";
import { PartnerUrl } from "../../partner/partner.url";

import { BrandCampaign } from "../../shared/models/brandCampaign";
import { Campaign } from "../../shared/models/campaign";

import {
  CampaignPartner,
  PartnerCampaign,
} from "../../shared/models/partnerCampaign";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { CampaignService } from "../../shared/services/api/campaign.service";
import { PartnerCampaignService } from "../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../shared/services/api/partner.service";
import { UserService } from "../../shared/services/api/user.service";
import { NotificationService } from "../../shared/services/notification.service";
import { TrackingService } from "../../shared/services/tracking.service";
import { NotFoundService } from "../../shared/services/not-found.service";
import { assert } from "../../shared/utils/assert";

enum InviteErrors {
  NoCampaignFoundForThisCode = "NO_CAMPAIGN_FOUND_FOR_THIS_CODE",
  AlreadyInvited = "ALREADY_INVITED",
}

@Component({
  selector: "app-campaign-invite-layout",
  templateUrl: "./campaign-invite-layout.component.html",
  styleUrls: ["./campaign-invite-layout.component.scss"],
})
export class CampaignInviteLayoutComponent implements OnInit {
  public campaign?: Campaign;
  public brandCampaign?: BrandCampaign;
  public partner?: CampaignPartner;
  public signUpBackgroundImage?: string;
  public campaignNotFound = false;
  public contentType?: "sign-up" | "invite";
  public isPreview: boolean;

  private partnerInviteToken?: string;
  private trackingEvent?: PartnersInviteTrackingEvents;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly campaignService: CampaignService,
    private readonly brandCampaignService: BrandCampaignService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly authService: AuthenticationService,
    private readonly partnerService: PartnerService,
    private readonly router: Router,
    private readonly notFoundService: NotFoundService,
    private readonly notificationService: NotificationService,
    private readonly userService: UserService,
    private readonly trackingService: TrackingService,
  ) {
    const path = this.activatedRoute.routeConfig?.path ?? "";

    // TODO: Pass `isPreview` as route data value instead of checking the URL here
    this.isPreview = path.includes("preview");
    this.partnerInviteToken =
      this.activatedRoute.snapshot.params.partner_invite_token;
  }

  public ngOnInit(): void {
    const slug = this.activatedRoute.snapshot.params.slug;

    if (slug) {
      if (this.authService.isUserLogged && !this.isPreview) {
        this.inviteLoggedUserHandler(slug);
        return;
      }
      this.getBrandCampaignSummaryInfo(slug);

      this.contentType = "sign-up";
    } else if (this.partnerInviteToken) {
      this.partnerCampaignService
        .getPartnerCampaignByInviteToken(this.partnerInviteToken)
        .then((campaign) => {
          this.trackInviteOpened(campaign, campaign.partner);
          this.campaignService.partnerInviteToken = this.partnerInviteToken;
          this.partner = campaign.partner;
          this.contentType = campaign.partner.isProspect ? "sign-up" : "invite";
          this.setCampaignData(campaign);
        })
        .catch((error) => {
          console.error(error);
          this.notificationService.error(
            "partner.campaignList.joinError.campaignNotFound",
          );
          this.notFoundService.redirect("invalid-partner-invite-token");
        });
    }
  }

  public onEventPartnerSignedUp(): void {
    assert(this.partner, "Partner is not defined");
    this.trackingEvent?.partnerSignedUp(this.partner.id);
  }

  public onEventPartnerAnsweredInvite(hasAccepted: boolean): void {
    assert(this.partner, "Partner is not defined");
    if (hasAccepted) {
      this.trackingEvent?.invitationAcceptedOnLandingPage(this.partner.id);
    } else {
      this.trackingEvent?.invitationRejectedOnLandingPage(this.partner.id);
    }
  }

  private trackInviteOpened(
    campaign: PartnerCampaign,
    partner: CampaignPartner,
  ): void {
    this.trackingEvent = new PartnersInviteTrackingEvents(
      campaign.brand.id,
      campaign.id,
      this.trackingService,
    );

    this.trackingEvent.invitationOpened(partner.id, !!partner.isProspect);
  }

  public async getBrandCampaignSummaryInfo(slug: string): Promise<void> {
    this.brandCampaign =
      await this.brandCampaignService.getBrandCampaignSummary(slug);
    if (!this.brandCampaign) {
      this.campaignNotFound = !this.brandCampaign;
      return;
    }
    this.campaign = this.brandCampaign as Campaign;
    this.campaignService.invitedCampaignId = this.campaign.id;
    this.signUpBackgroundImage = this.brandCampaign.signUpBackgroundImage;
  }

  private setCampaignData(campaign: PartnerCampaign): void {
    this.campaign = campaign;
    this.campaignService.invitedCampaignId = this.campaign.id;
    this.signUpBackgroundImage = campaign.signUpBackgroundImage;
  }

  protected async inviteLoggedUserHandler(slug: string): Promise<void> {
    const currentPartnerId = await this.partnerService.getPartnerIdFromUser();

    if (this.authService.isAnyAdmin()) {
      this.notificationService.error("partner.campaign.adminAsPartnerError");
    } else if (this.authService.isRolePartner() && !!currentPartnerId) {
      this.partnerService
        .addPartnerToCampaignFromLinkInvite(currentPartnerId, slug)
        .subscribe({
          error: (error) => {
            this.showJoinCampaignError(error);
          },
          complete: () => {
            this.router.navigate([PartnerUrl.Home(currentPartnerId)]);
          },
        });
    } else if (this.authService.isRoleBrand()) {
      await this.router.navigate(["/welcome"]);
    } else if (!this.authService.isRoleAdmin()) {
      const email = this.authService.userEmail;

      assert(email, "Email is not defined");

      this.userService.addUserToCampaign(email, slug).subscribe({
        error: (error) => {
          this.showJoinCampaignError(error);
        },
        complete: () => {
          this.router.navigate(["/welcome"]);
        },
      });
    }
  }

  private showJoinCampaignError(err: HttpErrorResponse): void {
    const error = err.error?.error;

    let errorMessage = "shared.errorPerformingTheAction";

    if (error?.key === InviteErrors.AlreadyInvited) {
      errorMessage = "partner.campaignList.joinError.alreadyInvited";
    } else if (error?.key === InviteErrors.NoCampaignFoundForThisCode) {
      errorMessage = "partner.campaignList.joinError.campaignNotFound";
    }

    this.notificationService.error(errorMessage);
  }
}
