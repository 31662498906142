import { Component, NgZone, OnInit, Renderer2 } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { WhiteLabelKeysList } from "./shared/models/white-label";
import { AuthTokenService } from "./shared/services/api/auth-token.service";
import { AuthenticationService } from "./shared/services/api/authentication.service";
import { UserService } from "./shared/services/api/user.service";
import { FeatureFlagsService } from "./shared/services/feature-flags.service";
import {
  FALLBACK_LOCALE,
  LanguageService,
  getInitialLocale,
} from "./shared/services/language.service";
import { LocalStorageService } from "./shared/services/local-storage.service";
import { TrackingService } from "./shared/services/tracking.service";
import { DateAdapter } from "@angular/material/core";
import { IntercomService } from "./shared/services/intercom.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-root",
  styleUrls: ["./app.component.scss"],
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  public loading = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly authTokenService: AuthTokenService,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly featureFlagsService: FeatureFlagsService,
    private readonly intercomService: IntercomService,
    private readonly languageService: LanguageService,
    private readonly localStorageService: LocalStorageService,
    private readonly meta: Meta,
    private readonly ngZone: NgZone,
    private readonly renderer: Renderer2,
    private readonly trackingService: TrackingService,
    private readonly translateService: TranslateService,
    private readonly userService: UserService,
  ) {
    this.checkIfResizeObserverIsAvailable();
    this.trackingService.init();
  }

  public ngOnInit(): void {
    this.initializeEventListeners();

    this.languageService.changed$.subscribe(({ locale }) => {
      this.dateAdapter.setLocale(locale);
      /* eslint-disable-next-line rxjs/no-ignored-observable */
      this.translateService.use(locale);
    });

    this.translateService.setDefaultLang(FALLBACK_LOCALE);
    this.languageService.setLocale(getInitialLocale());

    this.adjustViewportWidth(window.innerWidth);
    this.addWhiteLabelStyleVariables();

    let initAuthUser = Promise.resolve();

    if (this.authTokenService.userToken) {
      initAuthUser = lastValueFrom(this.userService.get()).then((user) =>
        this.authenticationService.setUser(user),
      );
    }

    initAuthUser.then(() => {
      this.featureFlagsService.load();
      this.intercomService.init();
    });
  }

  private initializeEventListeners(): void {
    this.ngZone.runOutsideAngular(() => {
      this.renderer.listen("window", "resize", (event: UIEvent) =>
        this.adjustViewportWidth((event.target as Window).innerWidth),
      );
    });
  }

  private adjustViewportWidth(width: number): void {
    if (!isNaN(width) && width < 1024) {
      this.meta.updateTag({
        name: "viewport",
        content: "width=1024, initial-scale=1",
      });
    } else {
      this.meta.updateTag({
        name: "viewport",
        content: "width=device-width, initial-scale=1",
      });
    }
  }

  private addWhiteLabelStyleVariables(): void {
    for (const key of WhiteLabelKeysList) {
      const value = this.localStorageService.get(key.storageKey);
      if (value) {
        document.documentElement.style.setProperty(key.styleVar, value);
      }
    }
  }

  private checkIfResizeObserverIsAvailable(): void {
    (async () => {
      if (!window.ResizeObserver) {
        const module = await import("@juggle/resize-observer");
        window.ResizeObserver = module.ResizeObserver;
      }
    })();
  }
}
