import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { NotificationService } from "../../shared/services/notification.service";
import { AppDataService } from "../../shared/services/api/app-data.service";
import { UserAccessService } from "../shared/services/user-access.service";
import { FeatureFlagsService } from "../../shared/services/feature-flags.service";
import { IntercomService } from "../../shared/services/intercom.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [AuthenticationService],
})
export class UserLoginComponent implements OnInit {
  protected model: any = {};
  protected isUnderMaintenance = false;
  protected loading = false;
  protected showPasswordField = false;
  @ViewChild("password") protected passwordField?: ElementRef<HTMLInputElement>;

  constructor(
    private readonly appDataService: AppDataService,
    private readonly authenticationService: AuthenticationService,
    private readonly featureFlagsService: FeatureFlagsService,
    private readonly intercomService: IntercomService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly userAccessService: UserAccessService,
  ) {}

  public ngOnInit(): void {
    this.authenticationService.logout();

    this.appDataService.get().then((config) => {
      if (config.isUnderMaintenance) {
        this.isUnderMaintenance = true;
      }
    });
  }

  protected login(form: NgForm): void {
    if (!form.controls.username.valid) {
      return;
    }

    if (this.showPasswordField && !form.valid) {
      return;
    }

    if (!this.showPasswordField) {
      this.checkIsSSOUser(form);
      return;
    }
    this.continueNonSSOLogin();
  }

  private continueNonSSOLogin(): void {
    this.loading = true;
    this.authenticationService
      .login(this.model.username, this.model.password)
      .subscribe({
        next: async () => {
          await this.featureFlagsService.load();
          this.refreshUserData();
        },
        error: () => {
          this.loading = false;
          this.notificationService.error("login.loginError");
        },
      });
  }

  private refreshUserData(): void {
    this.userAccessService.setUserData().subscribe(() => {
      this.intercomService.init();
      this.router.navigate(["welcome"]);
    });
  }

  private checkIsSSOUser(form: NgForm): void {
    this.loading = true;
    this.authenticationService.isSSoUser(this.model.username).subscribe({
      next: (isSSoUserResponse) => {
        if (isSSoUserResponse.isSSO) {
          this.startSSOLogin(isSSoUserResponse.brandId);
        } else {
          this.showPasswordField = true;
          const username = this.model.username;
          const password = this.model.password;
          form.resetForm();
          form.controls.username.setValue(username);
          form.controls.password.setValue(password);
          setTimeout(() => {
            this.passwordField?.nativeElement.focus();
          }, 200);
        }
      },
      error: () => {
        this.notificationService.error("login.loginError");
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  private startSSOLogin(brandId: number): void {
    this.loading = true;
    this.authenticationService.setSSOTemporaryBrandId(brandId.toString());
    this.authenticationService.getSSOLoginUrl(brandId).subscribe({
      next: (url: string) => {
        if (window) {
          this.loading = false;
          window.location.href = url;
        } else {
          this.notificationService.error("login.loginError");
        }
      },
      error: () => {
        this.loading = false;
        this.notificationService.error("login.loginError");
      },
    });
  }
}
