import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { LayoutModule } from "../layout/layout.module";
import { PartnerAcceptCampaignComponent } from "./campaign-invite-layout/partner-accept-campaign/partner-accept-campaign.component";
import { SharedComponentsModule } from "../shared/components/shared-components.module";
import { AngularMaterialModule } from "../shared/material/material.module";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { CampaignInviteLayoutComponent } from "./campaign-invite-layout/campaign-invite-layout.component";
import { UserLoginComponent } from "./login/login.component";
import { RecoverPasswordComponent } from "./recover-password/recover-password.component";
import { UserAccessService } from "./shared/services/user-access.service";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { SingleSignOnCallbackComponent } from "./single-sign-on-callback/single-sign-on-callback.component";
import { SingleSignOnIframeComponent } from "./single-sign-on-iframe/single-sign-on-iframe.component";

@NgModule({
  declarations: [
    CampaignInviteLayoutComponent,
    UserLoginComponent,
    PartnerAcceptCampaignComponent,
    RecoverPasswordComponent,
    SignUpComponent,
    SingleSignOnCallbackComponent,
    SingleSignOnIframeComponent,
  ],
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    LayoutModule,
    SharedComponentsModule,
    SharedPipesModule,
    AngularMaterialModule,
  ],
  exports: [SignUpComponent],
  providers: [UserAccessService],
})
export class AuthModule {}
